<template>
    <div>
        <client-only>
            <div class="news-letter">
                <div class="modal-backdrop fade show" v-if="showModal"></div>
                <div class="modal fade show d-block bd-example-modal-lg theme-modal" id="modal-newsletter"
                    aria-hidden="true" tabindex="-1" role="dialog" aria-labelledby="modal-cartLabel" v-if="showModal">
                    <div class="modal-dialog modal-lg modal-dialog-centered">
                        <div class="modal-content">
                            <div class="modal-body modal1">
                                <div class="container-fluid p-0">
                                    <div class="modal-bg">
                                        <button class="close btn-close" type="button" @click="closeCart()">
                                            <span>×</span>
                                        </button>
                                        <div class="row">
                                            <div class="col-12">
                                                <h2 class="text-success text-center">Don’t miss any new release !</h2>
                                                <h4 class="text-center"> Bring entertainment to every one.</h4>
                                            </div>
                                            <div class="col-6">
                                                <div class="offer-content">
                                                    <h3>Subscribe to our newsletter</h3>
                                                    <!-- <form class="auth-form needs-validation" target="_blank">
                                                <div class="form-group mx-sm-3">
                                                    <input type="email" class="form-control" name="EMAIL"
                                                        placeholder="Enter your email" required="required" />
                                                    <button type="submit" class="btn btn-solid"
                                                        id="mc-submit">subscribe</button>
                                                </div>
                                            </form> -->
                                                    <form @submit="handleSubmit">
                                                        <DxForm v-model:form-data="userData" :read-only="false"
                                                            @initialized="saveFormInstance"
                                                            :show-colon-after-label="true"
                                                            :show-validation-summary="true">
                                                            <DxGroupItem>
                                                                <DxSimpleItem data-field="firstName"
                                                                    :editor-options="firstNameEditorOptions">
                                                                </DxSimpleItem>
                                                                <DxSimpleItem data-field="lastName"
                                                                    :editor-options="usernameEditorOptions">
                                                                    <DxRequiredRule message="Lastname is required" />
                                                                </DxSimpleItem>
                                                                <DxSimpleItem data-field="email"
                                                                    :editor-options="emailEditorOptions">
                                                                    <DxRequiredRule message="Email is required" />
                                                                </DxSimpleItem>
                                                            </DxGroupItem>
                                                            <DxGroupItem css-class="last-group"
                                                                :col-count-by-screen="colCountByScreen">
                                                                <DxGroupItem :col-count-by-screen="colCountByScreen">
                                                                    <DxButtonItem @click="handleSubmit" css-class=" "
                                                                        :button-options="subscribeButtonOptions"> Login
                                                                    </DxButtonItem>
                                                                </DxGroupItem>
                                                            </DxGroupItem>
                                                        </DxForm>
                                                    </form>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="offer-content">
                                                    <h3>Create your customer account</h3>
                                                    <ul>
                                                        <li>🤝 Trusted Distributor - Your trusted source for the best in
                                                            gaming
                                                            and
                                                            pop
                                                            culture products.</li>
                                                        <li>
                                                            🌟 Quality Assured - Quality products from your favorite
                                                            brands,
                                                            guaranteed.
                                                        </li>
                                                        <li>
                                                            ⚡ Fast Delivery - Quick, reliable shipping to bring your
                                                            favorites
                                                            right
                                                            to
                                                            your door.
                                                        </li>
                                                        <li>
                                                            💬 Customer Care - Dedicated support for a seamless shopping
                                                            experience.
                                                        </li>
                                                    </ul>
                                                </div>
                                                <NuxtLinkLocale @click="closeCart()" class="btn btn-solid"
                                                    to="/account/pre-signup">
                                                    Let's become partners
                                                </NuxtLinkLocale>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </client-only>
    </div>
</template>

<script setup>
import DxForm, {
    DxGroupItem,
    DxSimpleItem,
    DxButtonItem,
    DxRequiredRule,
} from "devextreme-vue/form";
import nuxtStorage from "nuxt-storage";
import { useI18n } from "vue-i18n";
import { useFilterStore } from "~/store/FilterStore";
import { useUserStore } from "~/store/UserStore";
import { useUtilityStore } from "~/store/UtililityStore";
const utilityStore = useUtilityStore();
const filterStore = useFilterStore();
const userStore = useUserStore();
const { t } = useI18n();
const newsLetter = computed(() => utilityStore.newsLetter)
const showModal = computed(() => filterStore.showNewsLetterModal)// ref(false);
const nodeUser = computed(() => userStore.user);
const isAuthenticated = computed(() => userStore.isAuthenticated);

const props = defineProps({
    _email: {
        type: String,
    },
});
/// Dex Form
const formInstance = ref(null);
function saveFormInstance(e) {
    formInstance.value = e.component;
}
const firstNameEditorOptions = ref({
    valueChangeEvent: "input",
});
const usernameEditorOptions = ref({
    valueChangeEvent: "input",
});
const emailEditorOptions = ref({
    valueChangeEvent: 'input',
});
const userData = ref({ firstName: "", lastName: "", email: "" });
const initModal = async () => {
    try {
        const { _id, newsLetterSeen } = nodeUser.value
        const showModalStorage = localStorage.getItem("showModel");
        const _showModal = (showModalStorage !== 'newsletter' && !newsLetterSeen)
        if (_showModal) {
            filterStore.setShowNewsLetterModal(true)
            if (isAuthenticated.value) {
                // update user data
                setTimeout(() => {
                    localStorage.setItem("showModel", "newsletter");
                    // nuxtStorage.localStorage.setData("showModel", 'newsletter', 100, "y");
                    if (_id) {
                        userStore.updateUserNewsLetterStatus({ _id, newsLetterSeen: true })
                    }
                }, 4000);
            }
            else {
                localStorage.setItem("showModel", "newsletter")
                // nuxtStorage.localStorage.setData("showModel", 'newsletter', 100, "y");
            }
        }
        setTimeout(() => {
            if (props._email) {
                userData.value.email = props._email;
            }
        }, 0);
    } catch (error) {

    }
}

onNuxtReady(() => {
    setTimeout(() => {
        initModal();
    }, 2000);
})
const closeCart = () => {
    // showModal.value = false
    filterStore.setShowNewsLetterModal(false)
}
const handleSubmit = async (e) => {
    e.preventDefault()
    await utilityStore.createContactCrm({
        email: userData.value.email,
        firstName: userData.value.firstName,
        lastName: userData.value.lastName,
    });
    if (newsLetter.value) {
        useNuxtApp().$showToast({
            msg: t("newsletter_subscription"), //"Thank you for subscribing to our newsletter! 🎉",
            type: "success",
        });
        filterStore.setShowNewsLetterModal(false)
    } else {
        nuxtStorage.localStorage.removeItem("showModel");
        filterStore.setShowNewsLetterModal(false)
    }
}
const subscribeButtonOptions = ref({
    text: "Subscribe",
    type: "primary",
    useSubmitBehavior: true,
});


</script>
<style>
.modal-content {
    margin-top: 100px;
}

.news-letter {
    z-index: 99999;
}
</style>